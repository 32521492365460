<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="999"
    class="table-dctf-detail"
    dense
    hide-default-footer
    show-expand
  >
    <!-- <template v-slot:item.credit="{ item }">
      <div>
        Suspensão: <strong>{{ item.deducaoSuspensao | money | empty }}</strong>
      </div>
      <div>
        Compensação: <strong>{{ item.deducaoCompensacao | money | empty }}</strong>
      </div>
      <div>
        Salário-família: <strong>{{ item.deducaoSalFamilia | money | empty }}</strong>
      </div>
      <div>
        Salário-maternidade: <strong>{{ item.deducaoSalMaternidade | money | empty }}</strong>
      </div>
      <div>
        Retenção lei 9.711/98: <strong>{{ item.deducaoRetencao | money | empty }}</strong>
      </div>
    </template> -->
    
    <template v-slot:item.debitoApur="{ item }">
      <div class="bgColorRed">
        <strong>{{ item.debitoApur | money | emptyCurrency }}</strong>
      </div>
    </template>
    <template v-slot:item.deducaoSuspensao="{ item }">
      <div class="bgColorGreen">
        <strong>{{ item.deducaoSuspensao | money | emptyCurrency }}</strong>
      </div>
    </template>
    <template v-slot:item.deducaoCompensacao="{ item }">
      <div class="bgColorGreen">
        <strong>{{ item.deducaoCompensacao | money | emptyCurrency }}</strong>
      </div>
    </template>
    <template v-slot:item.deducaoSalFamilia="{ item }">
      <div class="bgColorYellow">
        <strong>{{ item.deducaoSalFamilia | money | emptyCurrency }}</strong>
      </div>
    </template>
    <template v-slot:item.deducaoSalMaternidade="{ item }">
      <strong class="bgColorGreen">{{ item.deducaoSalMaternidade | money | emptyCurrency }}</strong>
    </template>
    <template v-slot:item.deducaoRetencao="{ item }">
      <strong class="bgColorGreen">{{ item.deducaoRetencao | money | emptyCurrency }}</strong>
    </template>
    <template v-slot:item.deducaoPagamento="{ item }">
      <strong class="bgColorGreen">{{ item.deducaoPagamento | money | emptyCurrency }}</strong>
    </template>
    <template v-slot:item.deducaoSaldoPagar="{ item }">
      <strong>{{ item.deducaoSaldoPagar | money | emptyCurrency }}</strong>
    </template>

    <template v-slot:expanded-item="{ item }">
      <td class="grey lighten-3"></td>
      <td colspan="3" class="px-2 py-4 grey lighten-3">
        <Dcomp :id="item.id" :dctf-id="item.idDctf" />
      </td>
      <td colspan="6" class="grey lighten-3"></td>
    </template>
  </v-data-table>
</template>

<script>
import ECacService from '@/services/ecacService.js';
import _ from 'lodash'

export default {
  name: 'DctfDetalhe',

  components: {
    Dcomp: () => import('./Dcomp.vue'),
  },

  props: ['id'],

  data() {
    return {
      dialog: false,
      loading: false,
      headers: [],
      selectHeaders: [
        { text: 'Natureza', value: 'naturezaContribuicao', align: 'center' },
        { text: 'Débito apurado', value: 'debitoApur', align: 'center', sortable: false },
        { text: 'Suspensão', value: 'deducaoSuspensao', align: 'center', sortable: false },
        { text: 'Compensação', value: 'deducaoCompensacao', align: 'center', sortable: false },
        { text: 'Sal.família', value: 'deducaoSalFamilia', align: 'center', sortable: false }, 
        { text: 'Sal.maternidade', value: 'deducaoSalMaternidade', align: 'center', sortable: false }, 
        { text: 'Retenção lei 9.711/98', value: 'deducaoRetencao', align: 'center', sortable: false },
        { text: 'Pagamento', value: 'deducaoPagamento', align: 'center', sortable: false },
        { text: 'Saldo a pagar', value: 'deducaoSaldoPagar', align: 'center', sortable: false },
      ],

      items: [],
    };
  },

  created() {
    if (this.id) this.fetch(this.id);
  },

  methods: {
    async fetch(id) {
      this.loading = true;
      try {
        let response = await ECacService.getDCTF(id);
        this.items = response.data.content;
        this.showHeaders()
        this.$emit('items', this.items);
        // this.items = [...this.items, ...this.items, ...this.items, ...this.items];
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    showHeaders(){
      const deducaoSuspensao = 'deducaoSuspensao';
      const deducaoCompensacao = 'deducaoCompensacao';
      const deducaoSalFamilia = 'deducaoSalFamilia';
      const deducaoSalMaternidade = 'deducaoSalMaternidade';
      const deducaoRetencao = 'deducaoRetencao';
      const deducaoPagamento = 'deducaoPagamento';

      const headersList = [
        deducaoSuspensao, 
        deducaoCompensacao, 
        deducaoSalFamilia, 
        deducaoSalMaternidade,
        deducaoRetencao,
        deducaoPagamento
        ];

      headersList.forEach(i => this.removeHeader(i));
      this.headers = Object.values(this.selectHeaders);
    },
    removeHeader(item){
      const isNullObj = (key) => this.items.every( x => x[key] === null)
      
      if(isNullObj(item)){
        this.selectHeaders = this.selectHeaders.filter( x=>x.value !== item)
      }

    }
  }
};
</script>

<style scoped>
.table-dctf-detail >>> th {
  padding: 0 6px !important;
}
.table-dctf-detail >>> td {
  font-size: x-small !important;
  font-weight: 500;
}
.bgColorRed{
   background-color: #FFE3E3 !important;
     text-align: center !important;
}
.bgColorGreen{
   background-color: #c0f1cc !important;
     text-align: center !important;
}
.bgColorYellow{
  background-color: #FFEEAD !important;
  text-align: center !important;
}
</style>
