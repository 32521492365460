var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "table-dctf-detail",
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      "items-per-page": 999,
      dense: "",
      "hide-default-footer": "",
      "show-expand": "",
    },
    scopedSlots: _vm._u([
      {
        key: "item.debitoApur",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "bgColorRed" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm._f("emptyCurrency")(_vm._f("money")(item.debitoApur))
                  )
                ),
              ]),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoSuspensao",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "bgColorGreen" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm._f("emptyCurrency")(
                      _vm._f("money")(item.deducaoSuspensao)
                    )
                  )
                ),
              ]),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoCompensacao",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "bgColorGreen" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm._f("emptyCurrency")(
                      _vm._f("money")(item.deducaoCompensacao)
                    )
                  )
                ),
              ]),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoSalFamilia",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "bgColorYellow" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm._f("emptyCurrency")(
                      _vm._f("money")(item.deducaoSalFamilia)
                    )
                  )
                ),
              ]),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoSalMaternidade",
        fn: function ({ item }) {
          return [
            _c("strong", { staticClass: "bgColorGreen" }, [
              _vm._v(
                _vm._s(
                  _vm._f("emptyCurrency")(
                    _vm._f("money")(item.deducaoSalMaternidade)
                  )
                )
              ),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoRetencao",
        fn: function ({ item }) {
          return [
            _c("strong", { staticClass: "bgColorGreen" }, [
              _vm._v(
                _vm._s(
                  _vm._f("emptyCurrency")(_vm._f("money")(item.deducaoRetencao))
                )
              ),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoPagamento",
        fn: function ({ item }) {
          return [
            _c("strong", { staticClass: "bgColorGreen" }, [
              _vm._v(
                _vm._s(
                  _vm._f("emptyCurrency")(
                    _vm._f("money")(item.deducaoPagamento)
                  )
                )
              ),
            ]),
          ]
        },
      },
      {
        key: "item.deducaoSaldoPagar",
        fn: function ({ item }) {
          return [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm._f("emptyCurrency")(
                    _vm._f("money")(item.deducaoSaldoPagar)
                  )
                )
              ),
            ]),
          ]
        },
      },
      {
        key: "expanded-item",
        fn: function ({ item }) {
          return [
            _c("td", { staticClass: "grey lighten-3" }),
            _c(
              "td",
              {
                staticClass: "px-2 py-4 grey lighten-3",
                attrs: { colspan: "3" },
              },
              [_c("Dcomp", { attrs: { id: item.id, "dctf-id": item.idDctf } })],
              1
            ),
            _c("td", {
              staticClass: "grey lighten-3",
              attrs: { colspan: "6" },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }